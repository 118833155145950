/* eslint-disable no-lone-blocks */
import styles from "./WebPush.module.scss";
import { Image } from "antd";
import { Icon } from "@sunwing/components";
import config from "../../app/config";
import React, { useEffect, useState } from "react";

const WebPushPopup = ({ notification, handleWebPushClose, email }) => {
  const [userEmail, setUserEmail] = useState();

  useEffect(() => {
    setUserEmail(email);
    sendNotificationToSegment(notification, email, "Push Notification Received");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendNotificationToSegment = (notification, email, eventName) => {
    if (window.analytics) {   
      window.analytics.track(eventName, {
        code: notification.campaignCode,
        source: config.appCode,
        title: notification.title,
        email: email
      });
    }
  }

  const handleClick = (notification) => {
    sendNotificationToSegment(notification, userEmail, "Push Notification Clicked");
    
    if(notification.redirectUrl) 
      window.open(notification.redirectUrl, '_blank');
  
    window.location.reload();
  }

  const getOS = () => {
    const userAgent = window.navigator.userAgent,
        platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
        macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/Linux/.test(platform)) {
      os = 'Linux';
    }
  
    return os;
  }

  return (
    <>
        {!notification.redirectUrl && notification.redirectUrl !== '' ? 
          (<>
              <div className={`${styles.popupMainContainer} ${getOS() === 'Windows'?styles.windowPosition:styles.otherOsPosition}`}>
                <button onClick={() => handleClick(notification)} className={styles.buttonwWrapper}>
                  <div className={styles.logoContainer}>
                    <Image
                        src={'https://assets.sunwingtravelgroup.com/image/upload/f_auto/q_auto/v1614627905/sunwing-prod/Web%20Replatform%20Project/Logos/SunwingLogos/NewSunwingLogo-white.svg'}
                        alt=""
                        className={styles.logoImage}>
                      </Image>
                    </div>
                  <div className={styles.contentContainer}>
                    <div>
                      <span  className={styles.contentHeader}>
                            {notification.title.length > 20 ? 
                                notification.title.substring(0, 20) + "..." : 
                                notification.title
                            }
                          </span>
                    </div>
                    <span className={styles.contentBody}
                      dangerouslySetInnerHTML={{__html: 
                      `${notification.body.length > 50 ? 
                            notification.body.substring(0, 50) + "..." : 
                            notification.body
                      }`}}>
                    </span>
                  </div>
                </button>
              </div>
              <div className={styles.closeIcon}>
                <button onClick={() => handleWebPushClose()} className={styles.closeButton}>
                  <Icon name="close--n"/>
                </button>
              </div>
          </>):
          (<> 
            <div className={`${styles.popupMainContainer} ${getOS() === 'Windows'?styles.windowPosition:styles.otherOsPosition}`}>
              <button onClick={() =>handleClick(notification)} className={styles.buttonwWrapper}>
                <div className={styles.logoContainer}>
                  <Image
                      src={'https://assets.sunwingtravelgroup.com/image/upload/f_auto/q_auto/v1614627905/sunwing-prod/Web%20Replatform%20Project/Logos/SunwingLogos/NewSunwingLogo-white.svg'}
                      alt=""
                      className={styles.logoImage}>
                    </Image>
                </div>
                <div className={styles.contentContainer}>
                  <div>
                    <span  className={styles.contentHeader}>
                          {notification.title.length > 20 ? 
                              notification.title.substring(0, 20) + "..." : 
                              notification.title
                          }
                        </span>
                  </div>
                  <span className={styles.contentBody}
                    dangerouslySetInnerHTML={{__html: 
                    `${notification.body.length > 50 ? 
                          notification.body.substring(0, 50) + "..." : 
                          notification.body
                    }`}}>
                  </span>
                </div>
              </button>
              <div className={styles.closeIcon}>
                <button onClick={() => handleWebPushClose()} className={styles.closeButton}>
                  <Icon name="close--n"/>
                </button>
              </div>
            </div>
          </>)}
      </>
  );
};

export default WebPushPopup;