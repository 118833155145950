/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import moment from "moment";
import styles from "./InboxMessages.module.scss";
import { Icon } from "@sunwing/icons/dist/sunwing";
import { Image } from "antd";
import { RCL } from "../../components/RCL";
import config from "../../app/config";

const MessageItem = ({ active, message, email }) => {
  const [expanded, setExpanded] = useState(active);

  const image = message.keys?.filter(item => item.key === "image").length > 0 ? message.keys?.filter(item => item.key === "image")[0]?.value:"";
  const redirectUrl = message.keys?.filter(item => item.key === "redirectTo").length > 0 ? message.keys.filter(item => item.key === "redirectTo")[0]?.value:"";
  const inboxText = message.keys?.filter(item => item.key === "inboxText").length > 0 ? message.keys.filter(item => item.key === "inboxText")[0]?.value:"";
  const campaignCode = message.keys?.filter(item => item.key === "campaign").length > 0 ? message.keys.filter(item => item.key === "campaign")[0]?.value:"no campaign code";

  const handleClick = () => {
    if(!expanded){
      if (window.analytics) {
        window.analytics.track("Inbox Notification Clicked", {
          code: campaignCode,
          source: config.appCode,
          title:message.subject,
          email: email
        });
      }
    }

    setExpanded(!expanded);
  }
  return (
    <div>
      <span className={styles.messageHeader}>
      {message.subject}
        <a>
          <span className={styles.messageArrow} onClick={() => handleClick()}>
            {expanded ? <Icon name="arrow-up" /> : <Icon name="arrow-down" />}
          </span>
        </a>
      </span>
      <br />
      <span className={styles.messageHeaderDate}>{moment(message.startDateUtc).format("DD MMMM, YYYY, hh:mm a")}</span>
      {expanded && (
        <div className={styles.messageContainer}>
          {image && (<Image
                    src={image}
                    preview={false}
                    alt=""
              />)}
          <div className={styles.messageContent}>
            {inboxText && inboxText !=='' && 
              (<span dangerouslySetInnerHTML={{__html: `${inboxText}`}}>
              </span> )}
            {redirectUrl && redirectUrl !== '' &&  (<div>
              <a href={redirectUrl} target="_blank" rel="noreferrer">
                {redirectUrl}
              </a>
            </div>)}
            <span className={styles.messageFootDate}><strong><RCL searchKey="messages" /> <RCL searchKey="expires-on" /></strong> {moment(message.endDateUtc).format("DD MMMM, YYYY")}</span>
          </div>
        </div> 
      )}
      <br />
      <hr/>
      
    </div>
  )
};

export default MessageItem;