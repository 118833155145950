import React from "react";
import moment from "moment";
import config from "../../app/config";
import styles from "./WebPush.module.scss";

const handleClick = async (lang, id, message, email) => {

  const campaignCode = message.keys?.filter(item => item.key === "campaign").length > 0 ? message.keys.filter(item => item.key === "campaign")[0]?.value:"no campaign code";
  if (window.analytics) {

    await window.analytics.track("Inbox Notification Clicked", {
      code: campaignCode,
      source: config.appCode,
      title: message.subject,
      email: email
    });
  }

  window.location.href = window.location.origin + `/${lang}/InboxMessage?messageId=${id}&brand=${config.isWestJetMode ? "vwq" : "swg"}`;
}

const Entry = ({ lang, id, message, email }) => (
  <div>
    <button onClick={() => handleClick(lang, id, message, email)} className={styles.buttonwWrapper}>
      <span>{moment(message.startedAt).format("DD MMMM, YYYY, hh:mm a")}</span>
      <br></br>
      <span>{message?.title?.length > 30 ? 
                    message?.title?.substring(0, 30) + "..." : 
                    message?.title}</span>    
    </button>
    <hr/>
  </div>
);

export default Entry;